import React, { FC, useState, useCallback, useRef, useEffect } from 'react';
import classNames from 'classnames';

import UmbracoImage from 'components/common/Image/UmbracoImage';
import Button from 'components/common/Button';
import useScreenRecognition from 'hooks/useScreenRecognition';
import useClickOutside from 'hooks/useClickOutside';

import { ILessonCardProps } from './models';
import './LessonCard.scss';

const LessonCard: FC<ILessonCardProps> = ({ card, isListingPage, closeButtonAriaLabel }) => {
  const [showPDFImage, setShowPDFImage] = useState<boolean>(false);
  const imageRef = useRef<HTMLDivElement | null>(null);
  const { buttons, image, label, pdfImage, question, title } = card;

  const {
    properties: { altText, image: cardImage },
  } = image[0];

  const { isDesktop } = useScreenRecognition();

  const openPDFImage = useCallback(() => setShowPDFImage(true), []);
  const closePDFImage = useCallback(() => setShowPDFImage(false), []);

  useClickOutside(imageRef, closePDFImage);

  useEffect(() => {
    if (showPDFImage) {
      return document.body.classList.add('hide-body');
    }
    document.body.classList.remove('hide-body');

    return () => document.body.classList.remove('hide-body');
  }, [showPDFImage]);

  return (
    <>
      <div data-test="LessonCard" className="lesson-card__wrapper">
        <div className="lesson-card__label">{label}</div>
        {isDesktop ? (
          <div className="lesson-card__image-wrapper">
            <UmbracoImage image={cardImage} alt={altText} lazyLoad />
            <div
              className={classNames('lesson-card__title-block', {
                [`lesson-card__title-block--listing`]: isListingPage && isListingPage === '1',
              })}
            >
              <h3 className="lesson-card__title">{title}</h3>
              {question ? <div className="lesson-card__question">{question}</div> : null}
            </div>
          </div>
        ) : (
          <>
            <div className="lesson-card__image-wrapper">
              <UmbracoImage image={cardImage} alt={altText} lazyLoad />
            </div>
            <>
              <h3 className="lesson-card__title">{title}</h3>
              {question ? <div className="lesson-card__question">{question}</div> : null}
            </>
          </>
        )}
        <div className="lesson-card__buttons">
          {buttons?.map(({ properties }, index) => (
            <Button key={title + index.toString()} onClick={openPDFImage} {...properties}>
              {properties.title}
            </Button>
          ))}
        </div>
      </div>
      {showPDFImage && pdfImage.length ? (
        <div className="lesson-card__pdf-wrapper hide-body">
          <div ref={imageRef} className="lesson-card__pdf-image">
            <iframe
              title={buttons[1].properties.fileName || 'PDF'}
              width="100%"
              height="100%"
              src={buttons[1].properties.linkToPDF.fallbackUrl}
            />
            <Button
              ariaLabel={closeButtonAriaLabel || 'close pop-up'}
              onClick={closePDFImage}
              className="lesson-card__pdf-image--close"
            >
              <span className="line" />
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default LessonCard;
