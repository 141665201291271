/* eslint-disable import/prefer-default-export */
export const slidesToShow = (
  windowSize: number | false,
  blockSize: number,
  spaces: number
): number => {
  if (windowSize) {
    const maxAmount: number = Math.floor(windowSize / (blockSize + spaces));

    return maxAmount > 5 ? 5 : maxAmount;
  }

  return 0;
};
