import React, { FC, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import YouTube, { Options } from 'react-youtube';

import Button from 'components/common/Button';

import { getId } from './helpers';
import { IVideoBlockModalProps } from './models';
import './VideoBlockModal.scss';

const VideoBlockModal: FC<IVideoBlockModalProps> = ({
  children,
  videoLink,
  closeModalButton,
  isVideo,
  show,
  handleShow,
  handleClose,
  iframeTitle,
  buttonAriaLabelImage,
}) => {
  const {
    properties: { ariaLabel, title },
  } = closeModalButton[0];

  const getVideoId = useMemo(() => getId(videoLink), [videoLink]);

  const YToptions: Options = {
    playerVars: {
      autoplay: 1,
    },
    host: 'https://www.youtube-nocookie.com',
  };

  return (
    <div className="video-block-modal__wrapper">
      {children}
      {isVideo === '1' ? (
        <Button
          onClick={handleShow}
          className="video-block-modal__button-image"
          ariaLabel={buttonAriaLabelImage}
        >
          <div className="video-block-modal__button-image--triangle" />
        </Button>
      ) : null}

      <Modal
        dialogClassName="dt-media-info-box__media-modal"
        show={show}
        onHide={handleClose}
        centered
        size="xl"
      >
        <Modal.Body>
          <div className="dt-video-responsive-frame">
            {isVideo === '1' ? (
              <YouTube videoId={getVideoId} opts={YToptions} />
            ) : (
              <iframe title={iframeTitle} src={videoLink} />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button ariaLabel={ariaLabel} onClick={handleClose} variant="download">
            {title}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VideoBlockModal;
