import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'gatsby';

import UmbracoImage from 'components/common/Image/UmbracoImage';
import Logo from 'components/common/Logo';
import Button from 'components/common/Button';

import { IQUestPageBannerProps } from './models';
import './QuestBanner.scss';

const QuestBanner: FC<IQUestPageBannerProps> = ({ banner, brandSettings }) => {
  const {
    properties: { image, goBackButton, title, subTitle, downloadButton, dropdownButton },
  } = banner[0];

  const {
    properties: { altText, image: bannerImage },
  } = image[0];

  const {
    properties: { title: linkTitle, ariaLabel, link },
  } = goBackButton[0];

  const { properties: downloadButtonProperties } = downloadButton[0];

  return (
    <div className="quest-page-banner__wrapper">
      <div className="quest-page-banner__image-holder">
        <UmbracoImage image={bannerImage} alt={altText} lazyLoad />
      </div>
      <Container className="quest-page-banner__container">
        <div className="container-header">
          <Link to={link![0]!.url} aria-label={ariaLabel} className="container-header__link">
            <div className="icon-back  button-back" />
            {linkTitle}
          </Link>
          <Logo brandSettings={brandSettings} />
          <div className="container-header__buttons">
            <Button {...downloadButtonProperties} variant="download">
              {downloadButtonProperties.title}
            </Button>
            {dropdownButton?.[0] ? (
              <Button {...dropdownButton[0].properties} variant="white">
                {dropdownButton[0].properties.title}
                <span className="icon-dropdown_arrow_icon button-drop" />
              </Button>
            ) : null}
          </div>
        </div>
        <div className="container__text">
          <h1 className="container__title">
            {title}
            {banner[0].properties.boldTitle ? (
              <strong>{banner[0].properties.boldTitle}</strong>
            ) : null}
          </h1>
          <div className="container__sub-title">{subTitle}</div>
        </div>
      </Container>
    </div>
  );
};

export default QuestBanner;
