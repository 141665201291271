import React, { FC } from 'react';
import classNames from 'classnames';

import Button from 'components/common/Button';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { BLOCK_POSITION } from './constants';
import { ITextBlockProps } from './models';
import './TextBlock.scss';

const TextBlock: FC<ITextBlockProps> = ({ textBlock, handleShow, children }) => {
  const {
    properties: { title, titleGreen, text, button, blockPosition },
  } = textBlock[0];

  const { properties: textBlockButtonProperties } = button[0];

  const { isDesktop } = useScreenRecognition();

  return (
    <div
      data-test="TextBlock"
      className={classNames('text-block__wrapper', {
        'text-block__wrapper--reverse': blockPosition[0] === BLOCK_POSITION.left,
      })}
    >
      {isDesktop ? <>{children}</> : null}
      <div
        className={classNames('text-block__container', {
          'text-block__container--reverse': blockPosition[0] === BLOCK_POSITION.left,
        })}
      >
        <div className="text-block__title-block">
          <span className="text-block__title-block--black">{title}</span>
          <span className="text-block__title-block--green">{titleGreen}</span>
        </div>
        {!isDesktop ? <>{children}</> : null}
        <div className="text-block__text">{text}</div>
        <Button
          {...textBlockButtonProperties}
          variant="download"
          className="text-block__button"
          onClick={handleShow}
        >
          {textBlockButtonProperties.title}
        </Button>
      </div>
    </div>
  );
};

export default TextBlock;
