import React, { FC } from 'react';

import UmbracoImage from 'components/common/Image/UmbracoImage';
import Button from 'components/common/Button';
import VideoBlockModal from './VideoBlockModal';

import { IVideoBlockProps } from './models';
import './VideoBlock.scss';

const VideoBlock: FC<IVideoBlockProps> = ({ videoBlock, show, handleClose, handleShow }) => {
  const {
    properties: { videoLink, image, closeModalButton, isVideo, buttonAriaLabelImage },
  } = videoBlock[0];
  const {
    properties: { image: videoBlockImage, altText },
  } = image[0];

  return (
    <VideoBlockModal
      show={show}
      handleClose={handleClose}
      handleShow={handleShow}
      videoLink={videoLink}
      closeModalButton={closeModalButton}
      isVideo={isVideo}
      iframeTitle={videoBlock[0].properties.iframeTitle}
      buttonAriaLabelImage={buttonAriaLabelImage}
    >
      {isVideo === '1' ? (
        <UmbracoImage image={videoBlockImage} alt={altText} className="video-block__image" />
      ) : (
        <Button
          ariaLabel={buttonAriaLabelImage}
          className="video-block__button"
          onClick={handleShow}
        >
          <UmbracoImage
            image={videoBlockImage}
            alt={altText}
            className="video-block__image video-block__image--game"
          />
        </Button>
      )}
    </VideoBlockModal>
  );
};

export default VideoBlock;
