import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';

import useScreenRecognition from 'hooks/useScreenRecognition';

import { slidesToShow } from './helpers';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import { ICharactersFooterProps } from './models';
import './CharactersFooter.scss';

SwiperCore.use([Pagination]);

const CharactersFooter: FC<ICharactersFooterProps> = ({ footerCharacters, copyright }) => {
  const { windowWidth } = useScreenRecognition();

  return (
    <div className="quest-page-footer__wrapper">
      <Swiper
        spaceBetween={0}
        slidesPerView={slidesToShow(windowWidth, 277, 98)}
        pagination={!!(slidesToShow(windowWidth, 277, 98) !== 5)}
      >
        {footerCharacters?.map(({ properties: { title, altText, text, image } }) => (
          <SwiperSlide key={title} className="quest-page-footer__slide">
            <img className="quest-page-footer__image" src={`/images/${image}`} alt={altText} />
            <div className="quest-page-footer__title">{title}</div>
            <div className="quest-page-footer__text">{text}</div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="quest-page-footer__copyright">{copyright}</div>
    </div>
  );
};

export default CharactersFooter;
