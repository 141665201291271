import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import Button from 'components/common/Button';
import LessonCard from 'components/Lessons/LessonCard';

import { ILessonsProps } from './models';
import './Lessons.scss';

const Lessons: FC<ILessonsProps> = ({ lessons, isListingPage, closeButtonAriaLabel }) => {
  const {
    properties: { blackTitle, greenTitle, lessonCards, button },
  } = lessons[0];

  const { properties: downloadAllTopicsButtonProperties } = button[0];

  return (
    <section data-test="Lessons" className="lessons__wrapper">
      <Container className="lessons__container" fluid>
        <div className="lessons__title">
          {blackTitle ? <h2 className="lessons__title--black">{blackTitle}</h2> : null}
          {greenTitle ? <h2 className="lessons__title--green">{greenTitle}</h2> : null}
        </div>
        {lessonCards ? (
          <div className="lessons__card-section">
            {lessonCards.map(({ properties }) => (
              <LessonCard
                key={properties.label}
                card={properties}
                isListingPage={isListingPage}
                closeButtonAriaLabel={closeButtonAriaLabel}
              />
            ))}
          </div>
        ) : null}
        <Button
          {...downloadAllTopicsButtonProperties}
          className="lessons__button"
          variant="download"
        >
          {downloadAllTopicsButtonProperties.title}
        </Button>
      </Container>
    </section>
  );
};

export default Lessons;
