import React, { FC, useState, useCallback } from 'react';
import Container from 'react-bootstrap/Container';

import TextBlock from 'components/TextBlock';
import VideoBlock from 'components/VideoSection/VideoBlock';

import { IVideoSectionProps } from './models';
import './VideoSection.scss';

const VideoSection: FC<IVideoSectionProps> = ({ textBlock, videoBlock }) => {
  const [show, setShow] = useState(false);
  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(() => setShow(true), []);

  return (
    <div data-test="VideoSection" className="video-section__wrapper">
      <Container fluid>
        <TextBlock textBlock={textBlock} handleShow={handleShow}>
          <VideoBlock
            videoBlock={videoBlock}
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
          />
        </TextBlock>
      </Container>
    </div>
  );
};

export default VideoSection;
