import React, { FC } from 'react';
import { graphql } from 'gatsby';

import QuestBanner from 'gatsby-theme-dettol/src/components/QuestBanner';
import Lessons from 'gatsby-theme-dettol/src/components/Lessons';
import VideoSection from 'gatsby-theme-dettol/src/components/VideoSection';
import CharactersFooter from 'gatsby-theme-dettol/src/components/CharactersFooter';
import Seo from 'gatsby-theme-dettol/src/components/common/Seo';

import { IQuestPageProps } from 'gatsby-theme-dettol/src/templates/QuestPage/models';
import 'gatsby-theme-dettol/src/styles/main.scss';
import 'gatsby-theme-dettol/src/templates/QuestPage/QuestPage.scss';
import StructuredPageSchema from 'gatsby-theme-dettol/src/components/common/StructuredPageSchema';
import { schemaService } from 'gatsby-theme-dettol/src/utils/schemaService';

const QuestPage: FC<IQuestPageProps> = ({
  data: {
    questPage: {
      isListingPage,
      banner,
      lessons,
      videoSection,
      gameSection,
      footerCharacters,
      copyright,
      seoNoIndex,
      seoMetaKeywords,
      seoMetaDescription,
      seoMetaTitle,
      seoCanonicalUrl,
      seoExternalHreflangs,
      seoImage,
      ogPageType,
      link,
    },
    brandSettings,
    siteSettings,
  },
}) => {
  const {
    properties: { textBlock, videoBlock },
  } = videoSection[0];
  const {
    properties: { textBlock: textBlockGameSection, videoBlock: videoBlockGameSection },
  } = gameSection[0];

  const lang = siteSettings?.lang;

  const schemaData = schemaService.extractSchemaData({
    seo: {
      seoNoIndex,
      seoMetaKeywords,
      seoMetaDescription,
      seoMetaTitle,
      seoCanonicalUrl,
      seoExternalHreflangs,
      seoImage,
      ogPageType,
    },
    pagePathname: link,
    siteSettings,
    brandSettings,
    lang,
  });

  return (
    <div className="quest-page">
      {siteSettings?.buttonSkip ? (
        <a className="dt-skip-main" href="#dt-main" aria-label={siteSettings?.buttonSkip || 'Skip'}>
          {siteSettings?.buttonSkip}
        </a>
      ) : null}
      <Seo
        seoNoIndex={seoNoIndex}
        siteSettings={siteSettings}
        brandSettings={brandSettings}
        title={seoMetaTitle}
        description={seoMetaDescription}
        keywords={seoMetaKeywords}
        canonicalUrl={seoCanonicalUrl}
        externalHreflangs={seoExternalHreflangs}
        ogImage={seoImage}
        ogPageType={ogPageType}
        url={link}
      />
      <StructuredPageSchema schemaData={schemaData} />
      <div id="dt-main">
        <QuestBanner banner={banner} brandSettings={brandSettings} />
        <Lessons
          lessons={lessons}
          isListingPage={isListingPage}
          closeButtonAriaLabel={siteSettings.closeButtonAriaLabel}
        />
        <VideoSection textBlock={textBlock} videoBlock={videoBlock} />
        <VideoSection textBlock={textBlockGameSection} videoBlock={videoBlockGameSection} />
        <CharactersFooter footerCharacters={footerCharacters} copyright={copyright} />
      </div>
    </div>
  );
};

export const query = graphql`
  query ($lang: String, $link: String!) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    questPage(link: { eq: $link }, lang: { eq: $lang }) {
      seoMetaKeywords
      seoMetaDescription
      seoNoIndex
      seoMetaTitle
      seoCanonicalUrl
      isListingPage
      seoImage
      ogPageType
      seoExternalHreflangs {
        key
        value
      }
      copyright
      link
      footerCharacters {
        properties {
          title
          text
          image
          altText
        }
      }
      gameSection {
        properties {
          videoBlock {
            properties {
              videoLink
              isVideo
              iframeTitle
              buttonAriaLabelImage
              closeModalButton {
                properties {
                  ariaLabel
                  title
                }
              }
              image {
                properties {
                  image {
                    fluid {
                      originalImg
                      base64
                      srcSet
                    }
                  }
                  altText
                }
              }
            }
          }
          textBlock {
            properties {
              blockPosition
              text
              title
              titleGreen
              button {
                properties {
                  isGame
                  isOpenPopUp
                  isDropdown
                  isDownload
                  ariaLabel
                  title
                  link {
                    target
                    url
                  }
                }
              }
            }
          }
        }
      }
      videoSection {
        properties {
          textBlock {
            properties {
              text
              title
              titleGreen
              blockPosition
              button {
                properties {
                  isGame
                  isOpenPopUp
                  isDropdown
                  isDownload
                  ariaLabel
                  fileName
                  title
                  linkToPDF {
                    fallbackUrl
                    gatsbyDocFile {
                      relativePath
                    }
                  }
                }
              }
            }
          }
          videoBlock {
            properties {
              videoLink
              isVideo
              iframeTitle
              buttonAriaLabelImage
              closeModalButton {
                properties {
                  ariaLabel
                  title
                }
              }
              image {
                properties {
                  image {
                    fluid {
                      originalImg
                      base64
                      srcSet
                    }
                  }
                  altText
                }
              }
            }
          }
        }
      }
      banner {
        properties {
          title
          boldTitle
          subTitle
          dropdownButton {
            properties {
              isOpenPopUp
              isDropdown
              isDownload
              title
              ariaLabel
              link {
                url
                name
              }
            }
          }
          downloadButton {
            properties {
              isOpenPopUp
              isDropdown
              isDownload
              title
              ariaLabel
              fileName
              linkToPDF {
                fallbackUrl
                gatsbyDocFile {
                  relativePath
                }
              }
            }
          }
          goBackButton {
            properties {
              isOpenPopUp
              isDropdown
              isDownload
              ariaLabel
              title
              link {
                url
              }
            }
          }
          image {
            properties {
              altText
              image {
                fallbackUrl
                fluid {
                  base64
                  srcSet
                  originalImg
                }
              }
            }
          }
        }
      }
      lessons {
        properties {
          button {
            properties {
              isOpenPopUp
              isDropdown
              isDownload
              ariaLabel
              fileName
              title
              linkToPDF {
                fallbackUrl
                gatsbyDocFile {
                  relativePath
                }
              }
            }
          }
          blackTitle
          greenTitle
          lessonCards {
            properties {
              label
              question
              title
              pdfImage {
                properties {
                  altText
                  image {
                    fluid {
                      base64
                      srcSet
                      originalImg
                    }
                  }
                }
              }
              image {
                properties {
                  altText
                  image {
                    fluid {
                      base64
                      srcSet
                      originalImg
                    }
                  }
                }
              }
              buttons {
                properties {
                  isOpenPopUp
                  isDropdown
                  isDownload
                  ariaLabel
                  fileName
                  title
                  link {
                    name
                    url
                  }
                  linkToPDF {
                    fallbackUrl
                    gatsbyDocFile {
                      relativePath
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    brandSettings {
      brandName
      brandLogo {
        fallbackUrl
        fluid {
          srcSet
          base64
        }
      }
      brandLogoSchema
      youtubeUrl
      facebookUrl
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
  }
`;

export default QuestPage;
